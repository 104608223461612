// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-funraise-2941-js": () => import("./../../../src/pages/test-funraise-2941.js" /* webpackChunkName: "component---src-pages-test-funraise-2941-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-anniversary-18-js": () => import("./../../../src/templates/anniversary18.js" /* webpackChunkName: "component---src-templates-anniversary-18-js" */),
  "component---src-templates-anniversary-2023-js": () => import("./../../../src/templates/anniversary2023.js" /* webpackChunkName: "component---src-templates-anniversary-2023-js" */),
  "component---src-templates-anniversary-js": () => import("./../../../src/templates/anniversary.js" /* webpackChunkName: "component---src-templates-anniversary-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-christmas-js": () => import("./../../../src/templates/christmas.js" /* webpackChunkName: "component---src-templates-christmas-js" */),
  "component---src-templates-donate-australia-js": () => import("./../../../src/templates/donate-australia.js" /* webpackChunkName: "component---src-templates-donate-australia-js" */),
  "component---src-templates-donate-js": () => import("./../../../src/templates/donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-faith-js": () => import("./../../../src/templates/faith.js" /* webpackChunkName: "component---src-templates-faith-js" */),
  "component---src-templates-giving-thanks-dev-js": () => import("./../../../src/templates/giving-thanks-dev.js" /* webpackChunkName: "component---src-templates-giving-thanks-dev-js" */),
  "component---src-templates-giving-tuesday-2023-js": () => import("./../../../src/templates/giving-tuesday-2023.js" /* webpackChunkName: "component---src-templates-giving-tuesday-2023-js" */),
  "component---src-templates-giving-tuesday-2024-js": () => import("./../../../src/templates/giving-tuesday-2024.js" /* webpackChunkName: "component---src-templates-giving-tuesday-2024-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-meet-the-team-js": () => import("./../../../src/templates/meet-the-team.js" /* webpackChunkName: "component---src-templates-meet-the-team-js" */),
  "component---src-templates-newsletters-js": () => import("./../../../src/templates/newsletters.js" /* webpackChunkName: "component---src-templates-newsletters-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-sponsor-a-baby-party-js": () => import("./../../../src/templates/sponsor-a-baby-party.js" /* webpackChunkName: "component---src-templates-sponsor-a-baby-party-js" */),
  "component---src-templates-sponsor-a-mom-js": () => import("./../../../src/templates/sponsor-a-mom.js" /* webpackChunkName: "component---src-templates-sponsor-a-mom-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */),
  "component---src-templates-womens-month-js": () => import("./../../../src/templates/womens-month.js" /* webpackChunkName: "component---src-templates-womens-month-js" */)
}

